<template>
	<div class="tabs-play d-flex flex-column">
		<div class="d-flex">
			<div
				v-for="(tab, index) in tabs"
				:key="index"
				class="tabs-play_tab d-flex align-items-center py-2 mr-3"
				:class="tabClasses(index)"
				@click="handleClick(index)"
			>
				<IconSax
					v-if="tab.icon"
					:primary="selectedTab == index"
					:name="tab.icon"
					size="1rem"
					class="mr-2"
				/>
				<TextPlay
					tag="span"
					weight="bold"
					:text="tab.label"
				/>
			</div>
		</div>

		<div class="tabs-play_content mt-3">
			<slot :name="selectedTab">{{ selectedTab }}</slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		tabs: {
			type: Array,
			default: () => [],
		},
		selectedTab: {
			type: Number,
			default: 0,
		},
		enableToNextTab: {
			type: Boolean,
			default: false,
		},
		enableToBackTab: {
			type: Boolean,
			default: false,
		},
		enableAllTabs: {
			type: Boolean,
			default: false,
		},
		enablePreviousTabs: {
			type: Boolean,
			default: false,
		},
		enableNextTabs: {
			type: Boolean,
			default: false,
		},
		enabledTabs: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		handleClick(index) {
			const currentIndex = this.selectedTab;

			if (
				(currentIndex !== index && this.enableAllTabs) ||
				(this.enablePreviousTabs && index < this.selectedTab) ||
				(this.enableNextTabs && index > this.selectedTab)
			) {
				this.$emit("selectTab", index);
			}

			if ((this.enableToBackTab && index < currentIndex) || this.enabledTabs.includes(index)) {
				this.$emit("selectTab", index);
			}

			if ((index === currentIndex + 1 && this.enableToNextTab) || this.enabledTabs.includes(index)) {
				this.$emit("selectTab", index);
			}
		},
		isTabEnabled(index) {
			const currentIndex = this.selectedTab;
			if (index == currentIndex) return false;

			if (
				this.enableAllTabs ||
				this.enabledTabs.includes(index) ||
				(this.enablePreviousTabs && index < this.selectedTab) ||
				(this.enableNextTabs && index > this.selectedTab)
			)
				return true;

			if (this.enableToBackTab && index < currentIndex) {
				return true;
			}

			if (index === currentIndex + 1 && this.enableToNextTab) {
				return true;
			}

			return false;
		},
		tabClasses(index) {
			let isDisabled =
				!(this.selectedTab == index) &&
				!this.isTabEnabled(index) &&
				!this.enableAllTabs &&
				!this.enabledTabs.includes(index) &&
				!(this.enablePreviousTabs && index < this.selectedTab) &&
				!(this.enableNextTabs && index > this.selectedTab);

			return {
				"tabs-play_tab--active": this.selectedTab == index,
				"tabs-play_tab--disabled": isDisabled,
				"tabs-play_tab--closed": this.isTabEnabled(index) && !isDisabled && !(this.selectedTab == index),
			};
		},
	},
};
</script>

<style lang="scss">
.tabs-play {
	p,
	span {
		color: var(--fontcolor);
	}

	&_tab {
		font-weight: bold;
		cursor: pointer;

		&--active {
			border-bottom: 2px solid var(--maincolor);
		}

		&--disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}

		&--closed {
			opacity: 0.5;
		}
	}
}
</style>
