<template>
  <section id="Layout" class="layout h-screen w-screen">
    <!-- MODAL -->
    <ModalSale
      v-if="getModalBuyCourse.active"
      :data="modalContent"
      @close="
        actionShowModalBuyCourse({
          active: false,
          data: {},
        })
      "
    />
    <ModalUnavailable
      v-if="getModalInfoCourse && getModalInfoCourse.active"
      @close="
        actionShowModalInfoCourse({
          active: false,
          data: {},
        })
      "
    />

    <modal-painel v-if="getMe" />
    <!-- <modal-termos :siteid="currentSiteId"></modal-termos> -->
    <modal-novo-painel v-if="getMe"></modal-novo-painel>
    <my-component-certify></my-component-certify>
    <my-certificate-available></my-certificate-available>
    <my-issuance-certificate></my-issuance-certificate>
    <ModalCustomFields v-if="getMe" />
    <!-- <ModalPlanos :percentage="valuePercentage" /> -->
    <Sidebar />
    <div class="layout-page">
      <div class="topbar" id="topbar">
        <a
          @click="actionToggleSidebar"
          class="toggle-sidebar"
          v-if="sidebar.collapsible"
        >
          <IconSax name="menu-1" />
        </a>

        <router-link id="home" to="/home">
          <div
            :class="{
              'logo-mobile': layout.breakpoint == 1,
              'logo-tablet':
                layout.breakpoint === 2 ||
                (layout.breakpoint > 2 && sidebar.visible === false),
            }"
          >
            <template
              v-if="layout.breakpoint >= 2 && sidebar.visible === false"
            >
              <img v-if="metasSite?.logo" :src="metasSite?.logo" alt="logo" />
            </template>
            <template v-if="layout.breakpoint === 1">
              <img
                v-if="metasSite?.logomarca"
                :src="metasSite?.logomarca"
                alt="logo"
              />
            </template>
          </div>
        </router-link>

        <div class="flex-1" v-if="layout.breakpoint >= 2">
          <Search />
          <NPSResearch v-if="showLinkResearchNPS(false)" :link="getLinkResearchNPS" />
        </div>
        <TopbarUser />
      </div>
      <NPSResearch v-if="showLinkResearchNPS(true)" :link="getLinkResearchNPS" :is-mobile="isMobile" />
      <Search class="mobile-search" v-if="layout.breakpoint == 1" />
      <div class="layout-page__overflow">
        <div
          :class="{
            'site-content': true,
            'site-content--sidebar': sidebar.visible,
            'site-content--no-sidebar': sidebar.visible,
            'site-content--mobile': sidebar.visible && layout.breakpoint === 1,
            [contentClass]: true,
          }"
        >
          <slot></slot>
        </div>
        <div class="site-content--footer">
          <ScrollToTop />
          <Whatsapp v-if="whatsapp" />
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="modoFoco" v-if="luzApagada"></div>
    </transition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Sidebar from "./Sidebar.vue";
import Search from "@/components/search/Search.vue";
import TopbarUser from "./TopbarUser.vue";
import ModalPainel from "@/components/ModalPainel.vue";
import ModalPlanos from "@/components/ModalPlanos.vue";
import ModalCustomFields from "@/components/modals/CamposPersonalizados.vue";
import ModalNovoPainel from "@/components/ModalNovoPainel.vue";
import NPSResearch from "@/components/NPSResearch.vue";

import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();

export default {
  name: "Layout",
  components: {
    ScrollToTop: () => import("@/components/common/ScrollToTop.vue"),
    Whatsapp: () => import("@/components/common/Whatsapp.vue"),
    Sidebar,
    Search,
    TopbarUser,
    ModalPainel,
    ModalPlanos,
    ModalCustomFields,
    ModalNovoPainel,
    NPSResearch,
    ModalSale: () => import("@/components/common/modals/ModalSale.vue"),
    ModalUnavailable: () =>
      import("@/components/common/modals/ModalUnavailable.vue"),
  },
  props: ["contentClass"],
  data() {
    return {
      modalContent: {
        cover: {},
        title: null,
        description: null,
        button: null,
        url: null,
      },
      luzApagada: false,
      client: {
        width: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      metasSite: "user/metasSite",
      layout: "layout/getLayout",
      sidebar: "layout/getSidebar",
      getIsLoading: "config/getIsLoading",
      getMe: "auth/getMe",
      getModalInfoCourse: "home/getModalInfoCourse",
      getModalBuyCourse: "home/getModalBuyCourse",
      getSettings: "config/getSettings",
      getLinkResearchNPS: "config/getLinkResearchNPS"
    }),
    isMobile() {
      return this.client.width <= 576;
    },
    whatsapp() {
      const whatsapp = this.getSettings["whatsapp"];
      const number = this.getSettings["whatsapp_number"];

      const messageCheck =
        whatsapp &&
        whatsapp === true &&
        typeof number === "string" &&
        number.length > 0;

      return messageCheck;
    },
  },
  methods: {
    ...mapActions({
      actionDefineMainColor: "config/actionDefineMainColor",
      actionAllowRequest: "config/actionAllowRequest",
      actionSetIsLoading: "config/actionSetIsLoading",
      actionFetchHomeData: "home/actionFetchHomeData",
      actionFetchAllCoursesData: "myCourses/actionFetchAllCoursesData",
      actionFetchMe: "auth/actionFetchMe",
      actionLoadTheme: "config/actionLoadTheme",
      actionRefreshLayout: "layout/actionRefreshLayout",
      actionToggleSidebar: "layout/actionToggleSidebar",
      getEssayPermission: "user/getEssayPermission",
      getHasPermission: "user/getHasPermission",
      getCertificateConfig: "user/getCertificateConfig",
      getMetasSite: "user/getMetasSite",
      actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
      actionShowModalBuyCourse: "home/actionShowModalBuyCourse",
    }),
    showLinkResearchNPS(isMobile){
      return this.getLinkResearchNPS && this.isMobile == isMobile && this.getMe.member.role == 'student'
    },
    openModalBuyCourse(item) {
      this.modalContent = item;
      this.actionShowModalBuyCourse({
        active: true,
        data: this.modalContent,
      });
    },
    async openModal(item) {
      this.modalContent = {
        date: item?.date,
        type: item?.type,
      };

      this.actionShowModalInfoCourse({
        active: true,
        data: this.modalContent,
      });
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "Desktop";
    },
    initWhatsApp() {
      const enabled = this.getSettings["whatsapp"];
      const number = this.getSettings["whatsapp_number"];
      const message = this.getSettings["whatsapp_message"];

      if (!enabled & !number & !message) {
        return;
      }
    },
    initJivoChat() {
      const enabled = this.getSettings["jivochat"];
      const id = this.getSettings["id_jivochat"];
      if (!enabled || !id) {
        return;
      }
      const jivoScript = document.createElement("script");
      jivoScript.setAttribute("src", `//code.jivosite.com/widget/${id}`);
      jivoScript.setAttribute("id", "jivoscript");
      jivoScript.async = true;
      document.head.appendChild(jivoScript);

      jivoScript.onload = () => {
        this.adjustElementsPosition();
      };
    },
    setLocale(){
      const localeMap = {
        US: "en",
        ES: "es",
      };
      const defaultLocale = "pt";

      serviceMemberMeta
        .search("keys[]=pais")
        .then((resp) => {
          this.$i18n.locale = localeMap[resp.pais] || defaultLocale;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    adjustElementsPosition() {
      setTimeout(() => {
        const jvlabelWrap = document.getElementById("jvlabelWrap");
        const button = document.querySelector(".button_bcc8");

        if (jvlabelWrap) {
          jvlabelWrap.style.right = "70px";
        }
        this.observeJivoChat();
      }, 2000);
    },
    observeJivoChat() {
      var target = document.getElementById("jcont");
      if (target) {
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.type === "attributes") {
              setTimeout(() => {
                const jvlabelWrap = document.getElementById("jvlabelWrap");
                if (jvlabelWrap) {
                  jvlabelWrap.style.right = "70px";
                }
              }, 100);
            }
          });
        });

        var config = { attributes: true };
        observer.observe(target, config);
      } else {
        requestAnimationFrame(this.observeJivoChat);
      }
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.$root.$on("luzOn", () => {
      this.luzApagada = true;
    });
    this.$root.$on("luzOff", () => {
      this.luzApagada = false;
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  async created() {
    await this.actionSetIsLoading(true);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.setLocale()

    this.actionDefineMainColor();

    const promises = [
      this.actionRefreshLayout(),
      this.actionLoadTheme(),
      this.getEssayPermission(),
      this.getCertificateConfig(),
      this.getHasPermission()
    ];

    try {
      await Promise.allSettled(promises);

      const routePath = this.$route.path;

      if (routePath === "/home") {
        await this.actionFetchHomeData();
      } else if (routePath === "/my-courses") {
        await Promise.all([
          // this.actionFetchHomeData(),
          this.actionFetchAllCoursesData({ page: 1 }),
        ]);
      }

      if (this.detectOs() === "Android") {
        window.screen.orientation.lock("portrait");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
        this.actionSetIsLoading(false);
    }
    this.initWhatsApp();
    this.initJivoChat();
  },
};
</script>

<style lang="scss" scoped>
#home {
  .logo-mobile {
    min-height: 40px;
    flex-basis: 116px;
    align-items: center;
    display: flex;

    img {
      min-width: 116px;
      max-width: 116px;
      display: block;
    }
  }

  .logo-tablet {
    min-height: 40px;
    flex-basis: 38px;
    align-items: center;
    display: flex;

    img {
      min-width: 38px;
      max-width: 38px;
      display: block;
    }
  }
}

.layout {
  @include flex-row;
  overflow: hidden;
}

.layout-page {
  @include flex-column;
  flex: 1;
  width: 0;
  height: 100vh;

  &__overflow {
    // display: flex;
    // width: 100%;

    overflow: auto;
  }
}

.site-content {
  flex: 1;
  min-height: calc(100vh - 184px);
  height: 100%;
  &--mobile {
    overflow: hidden;
  }
}

.topbar {
  position: relative;
  background-color: var(--topbar-background);
  border-bottom: 1px solid var(--topbar-border);
  padding: 0.625rem 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  gap: 2.25rem;

  @media screen and (max-width: 600px) {
    justify-content: space-between;
    padding: 0.625rem 1rem;
    gap: 0;
  }
}

.toggle-sidebar {
  cursor: pointer;

  ::v-deep svg path {
    fill: var(--opposite-color);
  }
}

.mobile-search {
  padding: 1rem;
  flex-direction: row;
  width: 100%;
  height: 4.6rem;
}

.flex-1{
  display: flex;
  align-items: center;
}
</style>
