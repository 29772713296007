// mutations.js
export default {
	UPDATE_RANKING_CONFIG(state, config) {
		state.rankingConfig = config;
	},
	SET_EMBLEMS(state, emblems) {
		state.emblems = emblems;
	},
	SET_MEDALS(state, medals) {
		state.medals = medals;
	},
	SET_MEDAL(state, newMedal) {
		state.medals.push(newMedal);
	},
	UPDATE_MEDAL(state, updatedMedal) {
		const index = state.medals.findIndex(medal => medal.id === updatedMedal.id);
		state.medals[index] = updatedMedal;
	},
	SET_LAST_PAGE_RANKING_FOR_SELLER(state, ranking) {
		state.lastPageRankingForSeller = ranking;
	},
	SET_RANKING_FOR_STUDENT(state, ranking) {
		state.rankingForStudent = ranking;
	},
	SET_MEMBER(state, member) {
		state.member = member;
	},
	SET_MEMBERS_MEDALS(state, medals) {
		state.membersMedals = medals;
	},
	SET_STUDENT_COUPONS(state, coupons) {
		state.studentCoupons = coupons;
	},
	DELETE_STUDENT_COUPON(state, couponId) {
		state.studentCoupons.enabled = state.studentCoupons.enabled.filter(coupon => coupon.id !== couponId);
	},
	SET_STUDENT_COUPON(state, coupon) {
		state.studentCoupons.enabled.push(coupon);
	},
	DELETE_GAMIFICATION(state) {
		state.rankingConfig = {};
		state.emblems = [];
		state.medals = [];
		state.lastPageRankingForSeller = [];
		state.rankingForStudent = [];
		state.member = {};
		state.membersMedals = [];
		state.studentCoupons = {
			consumed: [],
			enabled: [],
			disabled: [],
		};
	},
};
