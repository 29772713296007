// actions.js
import Cookies from "js-cookie";

const transformOnToBoolean = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === "on") {
        obj[key] = true;
      } else if (obj[key] === "off") {
        obj[key] = false;
      }
    }
  }
  return obj;
};

import MetaService from "@/services/resources/MetaService";
import NPSService from "@/services/resources/NPSResearchService";

/* Services */
const serviceMeta = MetaService.build();

const serviceNps = NPSService.build();

export default {
  actionDefineTheme({ commit, state }, theme) {
    const hasCookieCurrentSite = JSON.parse(Cookies.get("currentSite") || "{}");

    const themeSave = {
      id: hasCookieCurrentSite.currentSite.id,
      theme: state.settings.theme_fixed_painel,
      color: theme,
    };

    const cacheStorage =
      JSON.parse(localStorage.getItem("themesPreferences")) || [];

    const themesTemp = [themeSave, ...cacheStorage];

    const removeDuplicates = themesTemp.filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );

    localStorage.setItem("themesPreferences", JSON.stringify(removeDuplicates));

    commit("config/SET_THEME", theme, { root: true });
  },
  actionSetIsLoading({ commit }, loading) {
    commit("config/SET_LOADING", loading, { root: true });
  },

  actionDefineMainColor({ commit }) {
    const storage = localStorage.getItem("mainColor");

    commit("config/SET_MAIN_COLOR", storage, {
      root: true,
    });
  },

  async actionLoadTheme({ commit, state }) {
    const currentSiteCookie = Cookies.get("currentSite");
    const currentSite = currentSiteCookie ? JSON.parse(currentSiteCookie) : {};
    const { theme_fixed_painel, theme_fixed_painel_color } = state.settings;

    if (theme_fixed_painel === false) {
      const themesPreferences =
        JSON.parse(localStorage.getItem("themesPreferences")) || [];
      const theme = themesPreferences.find(
        (objeto) => objeto.id === currentSite.currentSite.id
      );
      const selectedTheme = theme ? theme.color : "light";
      commit("SET_THEME", selectedTheme);
    } else {
      const theme =
        theme_fixed_painel === "on"
          ? theme_fixed_painel_color
          : Cookies.get("themeClub");
      const selectedTheme = theme || "light";
      commit("SET_THEME", selectedTheme);
    }
  },

  async actionSaveSettings({ dispatch }, config) {
    return new Promise(async (resolve, reject) => {
      try {
        await serviceMeta.postID(config);
        const response = await dispatch("actionFetchSettings");
        resolve(response);
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  },

  async actionFetchMetas({ dispatch, commit }, { keys }) {
    const keysPath = keys.map((key) => `keys[]=${key}`).join("&");
    const response = await serviceMeta.search(keysPath);
    return transformOnToBoolean(response);
  },

  async actionFetchNPSResearch({ dispatch, commit }) {
    const response = await serviceNps.search();
    commit("config/SET_NPS_LINK", response.link, { root: true });
  },

  async actionFetchSettings({ dispatch, commit }, key) {
    return new Promise(async (resolve, reject) => {
      const storage = localStorage.getItem("mainColor");

      commit("config/SET_MAIN_COLOR", storage, {
        root: true,
      });

      const settings = key
        ? [key]
        : [
            "evolucional_allowed",
            "study_schedule_allowed",
            "exercises_allowed",
            "introductoryVideo",
            "rating_home",
            "coment_lesson",
            "comment_review",
            "helper_data",
            "external_help",
            "external_help_link",
            "limit_devices_per_member",
            "jivochat",
            "whatsapp",
            "bitrix",
            "id_jivochat",
            "id_whatsapp",
            "whatsapp_code",
            "whatsapp_number",
            "whatsapp_message",
            "id_bitrix",
            "termsOfUse",
            "termsOfUseActivated",
            "first_access_enabled",
            "first_access_config",
            "limit_percetage_lesson",
            "number_limit_percetage_lesson",
            "complete_lessson_next",
            "number_limit_devices_per_member",
            "protected_font_size",
            "onboarding",
            "main_color",
            "without_category",
            "enable_category",
            "home_carousel",
            "home_vertical",
            "hide_courses",
            "theme_fixed_painel",
            "theme_fixed_painel_color",
            "learning_progression",
            "login_background_image",
            "link_to_privacy_policy",
            "link_to_user_terms",
            "link_menu_1_icon",
            "link_menu_1_label",
            "link_menu_1_url",
            "link_menu_1_newtab",
            "link_menu_2_icon",
            "link_menu_2_label",
            "link_menu_2_url",
            "link_menu_2_newtab",
            "link_menu_3_icon",
            "link_menu_3_label",
            "link_menu_3_url",
            "link_menu_3_newtab",
            "link_menu_4_icon",
            "link_menu_4_label",
            "link_menu_4_url",
            "link_menu_4_newtab",
            "logomarca",
            "logo",
            "pais",
            "google_gtm",
            "google_gtm_code",
            "gamefication"
          ];

      try {
        const mapSettings = settings.map((key) => `keys[]=${key}`).join("&");

        const response = await serviceMeta.search(mapSettings);

        if (response.google_gtm === true) {
          commit("config/SET_GTM", response.google_gtm_code, { root: true });
        }

        const isAdmin = Cookies.get("access_level");

        if (isAdmin) {
          commit("config/SET_MODE_ADMIN", isAdmin, { root: true });
        }

        commit("config/SET_CONFIG", transformOnToBoolean(response), {
          root: true,
        });

        if (response.theme_fixed_painel) {
          commit(
            "SET_THEME",
            (await response.theme_fixed_painel_color) || "light"
          );
        }

        commit("user/metasSite", response, { root: true });

        dispatch("actionFetchNPSResearch");

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
