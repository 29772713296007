export default {
	getRankingConfig: state => state.rankingConfig,
	getEmblems: state => state.emblems,
	getMedals: state => state.medals,
	getLastPageRankingForSeller: state => state.lastPageRankingForSeller,
	getRankingForStudent: state => state.rankingForStudent,
  getMember: state => state.member,
  getMembersMedals: state => state.membersMedals,
  getStudentCoupons: state => state.studentCoupons,
};
