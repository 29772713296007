<template>
	<div class="input-form-play p-0">
		<slot>
			<div class="d-flex flex-column p-0">
				<TextPlay
					v-if="label"
					class="mb-2"
					tag="label"
					:text="label"
					:color="labelColor"
					size="sm"
					:for="id"
					weight="semibold"
					:skeleton="skeleton"
				/>
				<InputPlay
					:skeleton="skeleton"
					:readonly="readonly"
					:disabled="disabled"
					:validation="validation"
					:name="name"
					:placeholder="placeholder"
					:preventNonNumeric="preventNonNumeric"
					:preventDots="preventDots"
					:preventHyphens="preventHyphens"
					:preventLetters="preventLetters"
					:preventSpaces="preventSpaces"
					:min="min"
					class="input-form-play__input-play"
					:type="type"
					@input="$emit('input', $event)"
					:value="value"
					@blur="validateField"
					:maxlength="maxlength"
				/>
				<TextPlay
					v-if="showErrorMessage"
					tag="span"
					v-show="errors.has(name)"
					class="input-form-play__error mt-1"
					:text="errors.first(name)"
					size="xs"
				/>
				<TextPlay
					v-if="description"
					class="input-form-play__description mt-2"
					tag="span"
					size="xs"
					:color="descColor"
					:text="description"
					:skeleton="skeleton"
				/>
			</div>
		</slot>
	</div>
</template>

<script>
export default {
	props: {
		label: String,
		name: String,
		placeholder: String,
		value: [String, Number],
		validation: [String, Object],
		id: String,
		description: String,
		descColor: {
			type: String,
			default: "var(--text-color)",
		},
		labelColor: {
			type: String,
			default: "var(--fontcolor)",
		},
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		maxlength: {
			type: [String, Number],
			default: 100,
		},
		type: {
			type: String,
			default: "text",
		},
		preventNonNumeric: {
			type: Boolean,
			default: false,
		},
		preventDots: {
			type: Boolean,
			default: false,
		},
		preventHyphens: {
			type: Boolean,
			default: false,
		},
		preventLetters: {
			type: Boolean,
			default: false,
		},
		preventSpaces: {
			type: Boolean,
			default: false,
		},
		min: {
			type: [String, Number],
		},
	},
	methods: {
		validateField() {
			this.$validator.validate(this.name);
		},
	},
	computed: {
		errors() {
			return this.$validator.errors;
		},
	},
};
</script>

<style lang="scss" scoped>
.input-form-play {
	&__error {
		color: var(--feedback-error-500, #ff3932);
	}
}
</style>
