import GamificationService from "@/services/resources/GamificationService";
const gamificationService = GamificationService.build();

export default {
	async fetchRankingConfig({ commit }) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read("/settings/ranking");
				commit("gamification/UPDATE_RANKING_CONFIG", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async setRankingConfig({ commit, dispatch }, rankingConfig) {
		return new Promise(async (resolve, reject) => {
			try {
				rankingConfig["id"] = "/settings/ranking";
				const response = await gamificationService.update(rankingConfig);
				commit("gamification/UPDATE_RANKING_CONFIG", response, { root: true });
				await dispatch(
					"config/actionSaveSettings",
					{
						id: "gamefication",
						value: "on",
					},
					{ root: true }
				);

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchEmblems({ commit }) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read("/emblem");
				commit("gamification/SET_EMBLEMS", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchMedals({ commit }) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read("/settings/medal");
				commit("gamification/SET_MEDALS", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async updateMedals({ commit }, medals) {
		return new Promise(async (resolve, reject) => {
			try {
				medals["id"] = "/settings/medals";
				const response = await gamificationService.update(medals);
				commit("gamification/UPDATE_MEDAL", medals, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchRankingForSeller({ commit }, page) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read(`/ranking/for/seller?page=${page}`);
				commit("gamification/SET_LAST_PAGE_RANKING_FOR_SELLER", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchRankingForStudent({ commit }) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read("/ranking/for/member");
				commit("gamification/SET_RANKING_FOR_STUDENT", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchMember({ commit }, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read(`/member/${id}`);
				commit("gamification/SET_MEMBER", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchMembersMedals({ commit }, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read(`/member/${id}/medals`);
				commit("gamification/SET_MEMBERS_MEDALS", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async setMedalManually(_, medal) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.post('/member/medals', medal);

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async setPointsManually(_, points) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.post('/member/points', points);

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchStudentCoupons({ commit }) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.read("/member/coupon");
				commit("gamification/SET_STUDENT_COUPONS", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async deleteStudentCoupons({ commit }, couponId) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.delete(`/member/coupon/${couponId}`); // o método para deletar é o delete?
				commit("gamification/DELETE_STUDENT_COUPON", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async setStudentCoupon({ commit }, coupon) {
		return new Promise(async (resolve, reject) => {
			try {
				coupon["id"] = "/member/coupon";
				const response = await gamificationService.post(coupon);
				commit("gamification/SET_STUDENT_COUPON", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async deleteGamification({ commit, dispatch }) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await gamificationService.delete("");
				await dispatch(
					"config/actionSaveSettings",
					{
						id: "gamefication",
						value: null,
					},
					{ root: true }
				);

				commit("gamification/DELETE_GAMIFICATION", response, { root: true });

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
};
