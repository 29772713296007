// state.js
export default {
	rankingConfig: {},
	emblems: [],
	medals: [],
	lastPageRankingForSeller: [],
	rankingForStudent: [],
	member: {},
	membersMedals: [],
	studentCoupons: {
		consumed: [],
		enabled: [],
		disabled: [],
	},
};
