<template>
    <div class="grid-icon-bell">
        <div class="icon-bell" ref="button" @click="toggleModal">
            <IconSax type="outline" name="notification" :class="[{'icon-color': showModal }]" />
            <span v-if="notifyCount != 0" class="icon-bell__count">{{ notifyCount }}</span>
        </div>

        <div v-if="showModal" class="notification-modal" v-closable="{
            exclude: ['button'],
            handler: 'onClose',
          }">
            <div class="notification-modal__header">
              <div class="notification-modal__header__title">Notificações</div>
              <!-- <router-link to="/notifications" class="route-element">
                <div class="notification-modal__header__action" @click="toNotificationList">Ver todas</div>
              </router-link> -->
              <div class="notification-modal__header__action" @click="toNotificationList">Ver todas</div>
            </div>
            <ul class="notification-list" v-if="notifications.length>0">
              <li  v-for="(notification, index) in notifies" :key="index">
                <div class="notification-container" @click="notificationAction(notification)">
                    <div class="notification-icon">
                        <div class="notification-icon-wrapper">
                          <IconSax v-if="!notification.read_at" class="notification-icon-inner" type="bold" name="notification" />
                          <IconSax v-else  type="outline" name="notification" />
                        </div>
                    </div>
                    <div class="notification-content">
                        <div class="notification-text">
                            <div class="notification-date">{{ showDate(notification.created_at) }}</div>
                            <div :class="notification.read_at ? 'notification-message-outline' : 'notification-message'">
                              {{ truncateString(notification.message, 89) }}
                            </div>
                        </div>
                        <div class="notification-footer">{{ truncateString(notification.title, 50) }}</div>
                    </div>
                </div>
              </li>
            </ul>
            <div v-else class="no-notifications">
              <div class="no-notifications__icon-container">
                  <div class="no-notifications__icon-wrapper">
                      <div class="no-notifications__icon">
                          <div>
                            <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="Vector" d="M26.5436 45.338C21.3981 45.338 16.2527 44.5209 11.3723 42.8867C9.5173 42.2463 8.10397 40.9434 7.48564 39.243C6.84522 37.5426 7.06605 35.6655 8.08189 33.9871L10.6215 29.7692C11.1515 28.8859 11.6373 27.1192 11.6373 26.0813V19.6992C11.6373 11.4842 18.3286 4.79297 26.5436 4.79297C34.7586 4.79297 41.4498 11.4842 41.4498 19.6992V26.0813C41.4498 27.0971 41.9356 28.8859 42.4656 29.7913L44.9831 33.9871C45.9327 35.5771 46.1094 37.4984 45.469 39.243C44.8286 40.9876 43.4373 42.3126 41.6927 42.8867C36.8344 44.5209 31.689 45.338 26.5436 45.338ZM26.5436 8.10547C20.1615 8.10547 14.9498 13.2951 14.9498 19.6992V26.0813C14.9498 27.6934 14.2873 30.0784 13.4702 31.4696L10.9306 35.6876C10.4448 36.5046 10.3123 37.3659 10.5994 38.0946C10.8644 38.8455 11.5269 39.4196 12.4323 39.7288C21.6631 42.8205 31.4461 42.8205 40.6769 39.7288C41.4719 39.4638 42.0902 38.8676 42.3773 38.0726C42.6644 37.2776 42.5981 36.4163 42.1565 35.6876L39.6169 31.4696C38.7777 30.0342 38.1373 27.6713 38.1373 26.0592V19.6992C38.1373 13.2951 32.9477 8.10547 26.5436 8.10547Z" fill="var(--maincolor)"/>
                              <path id="Vector_2" d="M30.6502 8.70047C30.4956 8.70047 30.3411 8.67838 30.1865 8.63422C29.5461 8.45755 28.9277 8.32505 28.3315 8.23672C26.4544 7.9938 24.6436 8.1263 22.9431 8.63422C22.3248 8.83297 21.6623 8.63422 21.2427 8.17047C20.8231 7.70672 20.6906 7.04422 20.9336 6.44797C21.839 4.12922 24.0473 2.60547 26.5648 2.60547C29.0823 2.60547 31.2906 4.10714 32.1961 6.44797C32.4169 7.04422 32.3065 7.70672 31.8869 8.17047C31.5556 8.5238 31.0919 8.70047 30.6502 8.70047Z" fill="var(--maincolor)"/>
                              <path id="Vector_3" d="M26.543 50.3711C24.3567 50.3711 22.2367 49.4878 20.6909 47.9419C19.1451 46.3961 18.2617 44.2761 18.2617 42.0898H21.5742C21.5742 43.3928 22.1042 44.6736 23.0317 45.6011C23.9592 46.5286 25.2401 47.0586 26.543 47.0586C29.2813 47.0586 31.5117 44.8282 31.5117 42.0898H34.8242C34.8242 46.6611 31.1142 50.3711 26.543 50.3711Z" fill="var(--maincolor)"/>
                              <path id="Vector 30" d="M48.8867 6.14844L4.11084 50.9243" stroke="var(--maincolor)" stroke-width="4.07053" stroke-linecap="round"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="no-notifications__content">
                  <div class="no-notifications__title-container">
                      <div class="no-notifications__title">Você não possui notificações</div>
                  </div>
                  <div class="no-notifications__message">
                      Nós avisaremos quando as novidades chegarem!
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>

import NotificationService from "@/services/resources/NotificationService";
const notificationService = NotificationService.build();
import moment from "moment";

export default {
  data() {
    return {
      showModal: false,
      notifications: [],
      client: {
        width: 0,
      }
    };
  },
  mounted(){
    this.getNotifications();

    this.intervalId = setInterval(() => {
      this.getNotifications();
    }, 300000); // 300.000 milissegundos = 5 minutos
  },
  computed: {
    notifyCount() {
      const length = this.notifications.filter(item => item.read_at === null).length;
      return length > 9 ? '9+' : length.toString();
    },
    notifies(){
      return  this.notifications.slice(0, 10);
    },
    isMobile() {
      return this.client.width <= 576;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    onClose() {
      this.showModal = false;
    },
    showDate(created_at){
      if(this.isToday(new Date(created_at))){
        return "Hoje"
      }

      return moment(created_at).format("DD/MM/YYYY")
    },
    isToday(dateStr) {
      return moment(dateStr, 'DD/MM/YYYY').isSame(moment(), 'day');
    },
    async getNotifications(){
      await notificationService
        .getAllNotifications()
        .then((resp) => {
          this.notifications = resp;
        })
    },
    async markAsRead(id){
      await notificationService
        .markAsRead(id)
        .then((resp) => {
          this.notifications = resp;
        })
    },
    toNotificationList() {
      if(this.$router.history.current.path === '/notifications'){
        this.showModal = !this.showModal;
      }else{
        this.$router.push({ name: 'Notifications' });
      }
    },
    toggleModal() {
      if(this.isMobile){
        this.$router.push({ name: 'Notifications' });
      }else{
        this.showModal = !this.showModal;
      }
    },
    truncateString(message, lenght){
      if (message.length > lenght) {
        return message.substring(0, lenght) + '...';
      }
      return message;
    },
    async notificationAction(notification){
      await this.markAsRead(notification.id);

      if(notification.link){
        this.$router.push({ path: '/' + notification.link });
        this.$router.go();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.no-notifications {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.no-notifications__icon-container {
    width: 64px;
    height: 64px;
    padding: 5px 6px 6px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-notifications__icon-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-notifications__icon {
    width: 53px;
    height: 53px;
    position: relative;
}

.no-notifications__icon-border {
    width: 0;
    height: 63.32px;
    position: absolute;
    left: 48.89px;
    top: 6.15px;
    border: 4.07px solid var(--maincolor);
    transform: rotate(45deg);
    transform-origin: 0 0;
}

.no-notifications__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.no-notifications__title-container {
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.no-notifications__title {
    text-align: center;
    color: var(--fontcolor);
    font-size: 18px;
    font-weight: 600;
    line-height: 21.60px;
    word-wrap: break-word;
}

.no-notifications__message {
    width: 235px;
    text-align: center;
    color: #878787;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
}

.notification-list{
  overflow-y: auto;
  background-color: var(--neutral-gray-50);
  width: 100%;
  height: 86.5%;
  margin-bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  [data-theme="dark"] & {
    background-color: #1E1E20;
    height: 86.39%;
    :hover{
      background-color: #262626;
    }
  }

  :hover{
    background-color: var(--neutral-gray-80);
  }

  li:last-child div{
    border-bottom: none;
  }
}

::-webkit-scrollbar-thumb{
  border-radius: 8px;
}

::-webkit-scrollbar-track{
  border-radius: 8px;
}

::-webkit-scrollbar{
  border-radius: 8px;
}

.grid-icon-bell{
  position:relative;
  right: 24px;
  align-items: center;
  display: flex;
}

.icon-bell{
    cursor: pointer;
    position: relative;
    &__count{
      position: absolute;
      top: -4px;
      left: 10px;
      display: inline-block;
      background: var(--Feedback-Error-500, #FF3932);
      padding: 0 2px;
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      border-radius: 22px;
      text-align: center;
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
      color: #fff;
    }
    // @media only screen and (max-width: 500px) {
    //   left:30px;
    // }
}

.notification-modal {
  @include flex-column;
  animation: showRight 0.3s forwards;
  display: flex;
  width: 400px;
  height: 470px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  position: absolute;
  top: 3rem;
  right: 0;
  background-color: var(--topbar-background);
  border: 1px solid var(--neutral-gray-100);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;

  [data-theme="dark"] & {
    border: 1px solid var(--neutral-gray-700);
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
  }

  &__header{
    display: flex;
    padding: 20px 24px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid var(--neutral-gray-100);

    [data-theme="dark"] &{
      border-bottom: 1px solid var(--neutral-gray-700);
    }

    &__title{
      color: var(--fontcolor);
      font-size: 18px;
      font-weight: 600; 
      line-height: 21.60px; 
      word-wrap: break-word
    }

    &__action{
      color: var(--maincolor);
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.60px; 
      word-wrap: break-word
    }
  }

  &__h3{
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  &__ul{
    list-style: none;
    padding-left: 0;
  }

  &__li{
    padding: 5px 0;
  }
}

ul{
  list-style: none;
  padding: 0;
}

.notification-container {
    cursor:pointer;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid var(--neutral-gray-100);
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    list-style: none;
    margin: 0;

    [data-theme="dark"] & {
      border-bottom: 1px solid var(--neutral-gray-700);
    }
}

.notification-icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-icon-wrapper {
    width: 16px;
    height: 16px;
    position: relative;
}

.notification-icon-inner {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 1.33px;

    ::v-deep .svg-inner path {
      fill: var(--maincolor);
    }
}

.notification-icon-background {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    left: 16px;
    transform: rotate(-180deg);
    transform-origin: 0 0;
    opacity: 0;
    background: var(--maincolor);
}

.notification-content {
    width: 336px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.notification-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
}

.notification-date {
    color: #878787;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    word-wrap: break-word;
}

.notification-message, .notification-message-outline {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    word-wrap: break-word;
    align-self: stretch;
}

.notification-message{
  color: var(--fontcolor);
}

.notification-message-outline {
    color: var(--neutral-gray-400);
}

.notification-footer {
    color: #878787;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    word-wrap: break-word;
    align-self: stretch;
}

.route-element:hover{
  text-decoration: none;
}

</style>